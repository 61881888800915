import { environment } from "src/environments/environment";

export interface RoutesGroup {
  All?: string;
  Entity?: (id?: number | string) => string;
  Custom?: (path: string[] | string) => string;
}

const BuildRoutesGroup = (root: string): RoutesGroup => {
  return {
    All: `${root}`,
    Entity: (id: number | string): string => `${root}/${id}`,
    Custom: (path: string | string[]) =>
      typeof path === "string" ? `${root}/${path}` : `${root}/${path.join("/")}`,
  };
};

export class APIRoutes {
  private readonly root = environment.apiRoot;

  private readonly patientsRoot = environment.apiPatientsRoot;

  public Apps: RoutesGroup = BuildRoutesGroup(`${this.root}/apps`);

  public Executions: RoutesGroup = BuildRoutesGroup(`${this.root}/data/executions`);

  public Patients: RoutesGroup = BuildRoutesGroup(`${this.patientsRoot}/patients`);

  public Password: RoutesGroup = BuildRoutesGroup(`${this.root}/password`);

  public Sessions: RoutesGroup = BuildRoutesGroup(`${this.root}/apps/users/sessions`);

  public Schedulings: RoutesGroup = BuildRoutesGroup(`${this.root}/scheduling`);

  public SchedulingSessions: RoutesGroup = BuildRoutesGroup(`${this.root}/scheduling/sessions`);

  public SchedulingSessionTasks: RoutesGroup = BuildRoutesGroup(
    `${this.root}/scheduling/session_tasks`,
  );

  public SessionManagement: RoutesGroup = BuildRoutesGroup(
    `${this.root}/session-management/sessions`,
  );

  public Tasks: RoutesGroup = BuildRoutesGroup(`${this.root}/tasks`);

  public TaskCategories: RoutesGroup = BuildRoutesGroup(`${this.root}/tasks/categories`);

  public Users: RoutesGroup = BuildRoutesGroup(`${this.root}/apps/users`);

  public Passwords: RoutesGroup = BuildRoutesGroup(`${this.root}/apps/password`);
}
