import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IsLoggedInGuard } from "@guards/is-logged-in.guard";
import { IsLoggedOutGuard } from "@guards/is-logged-out.guard";

const routes: Routes = [
  {
    path: "auth",
    canActivate: [IsLoggedOutGuard],
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    canActivate: [IsLoggedInGuard],
    loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
