import * as Moment from "moment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APIRoutes } from "@models/api-routes.models";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { tap } from "rxjs/operators";
import { AuthUser } from "@models/user.models";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly routes = new APIRoutes();

  public $refreshAt = new BehaviorSubject<string>(null);

  public set authUser(value: AuthUser) {
    localStorage.setItem("authUser", value ? JSON.stringify(value) : "");
  }

  public get authUser(): AuthUser {
    const authUser = localStorage.getItem("authUser");
    return authUser ? (JSON.parse(authUser) as AuthUser) : undefined;
  }

  constructor(private readonly http: HttpClient) {
    const authUser = this.authUser;
    if (!authUser) {
      return;
    }
    this.$refreshAt.next(Moment.utc(this.authUser.expires).format());
  }

  public login({ email, password }: { email: string; password: string }): Observable<any> {
    return this.http
      .post<any>(this.routes.Apps.Custom([`${environment.appId}`, "users", "sessions"]), {
        email,
        password,
      })
      .pipe(
        tap((data) => {
          this.authUser = data;
          this.$refreshAt.next(Moment.utc(this.authUser.expires).format());
        }),
      );
  }

  public forgotPassword({ email }: { email: string }): Observable<any> {
    return this.http.post<any>(this.routes.Passwords.Custom([`email`]), {
      email,
    });
  }

  public refreshToken(): Observable<any> {
    return this.http.put<any>(this.routes.Users.Custom([`sessions`]), {}).pipe(
      tap(({ expires }) => {
        const authUser = this.authUser;
        if (!authUser) {
          return;
        }
        authUser.expires = expires;
        this.authUser = authUser;
        this.$refreshAt.next(Moment.utc(this.authUser.expires).format());
      }),
    );
  }

  public resetPassword({
    email,
    token,
    password,
    password_confirmation,
  }: {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
  }): Observable<any> {
    return this.http.post<any>(this.routes.Passwords.Custom([`reset`, token]), {
      email,
      token,
      password,
      password_confirmation,
    });
  }

  public logout(): void {
    this.authUser = null;
  }
}
