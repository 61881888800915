import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingBarService } from "@services/loading-bar.service";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";

/**
 * This Interceptor is the first handler in the interception barrel
 *
 * This handles errors, and client-facing interactions, such as:
 *  - The loading bar,
 *  - The HTTP error handling
 */
@Injectable({
  providedIn: "root",
})
export class EventHandlerInterceptor implements HttpInterceptor {
  constructor(private readonly loadingBarService: LoadingBarService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingBarService.show();

    return next.handle(request).pipe(
      map((event) => {
        return event;
      }),
      finalize(() => {
        this.loadingBarService.hide();
      }),
    );
  }
}
